<template>
  <div>
    <v-progress-linear fixed color="primary" indeterminate v-if="loading"></v-progress-linear>
    <v-container>
      <v-row>
        <v-col>
          <h2 class="mt-3">Checkliste ausfüllen</h2>
          <h3 class="mb-0" v-if="checklist">{{ checklist.identifier }}</h3>
        </v-col>
      </v-row>
      <!-- Checklisten Items -->
      <v-form v-if="checklist" ref="form" style="width: 100%">
        <v-row  v-for="(item, index) in checklist.items" :key="item._id">
          <v-col cols="12" md="12">
            <v-card class="my-4" elevation="2">
              <v-row>
                <v-col cols="12" md="8" class="">
                  <v-card-title>
                    <h4>{{ item.title }}</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="item-body" v-html="item.body"></div>
                      </v-col>
                    </v-row>
                    <v-row v-if="item.video_path">
                      <v-col>
                        <v-btn v-if="item.video_path" :loading="loading" icon fab elevation="2" @click="showVideo(item.video_path)">
                          <v-icon>mdi-video</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-divider style="margin: 0 -1px" vertical></v-divider>
                <v-col cols="12" md="4">
                  <div class="d-flex flex-column" style="height: 100%">
                    <div></div>
                    <v-spacer></v-spacer>
                    <div class="item-form px-4">
                      <v-radio-group v-model="items[index].is_ok"
                                     @change="setShowRemark(index, items[index].is_ok)"
                                     :disabled="readOnly"
                                     required>
                        <v-radio
                            label="OK"
                            color="success"
                            :value="true"
                            required
                        ></v-radio>
                        <v-radio
                            label="nicht i.O."
                            color="red darken-3"
                            :value="false"
                        ></v-radio>
                      </v-radio-group>
                      <v-text-field
                          v-if="!showRemark[index]"
                          v-model="items[index].remark"
                          :counter="255"
                          :disabled="readOnly"
                          label="Kommentar"
                          required
                          :rules="textRules"
                          clearable
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

        </v-row>
      </v-form>
      <!-- End Checklisten Items -->
      <v-row>
        <v-col cols="12" md="12" class="text-right">
          <div v-if="newList === 'true' || newList === undefined">
            <v-btn fab class="mx-3" @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>

            </v-btn>

            <v-btn fab :loading="loading" class="" color="primary" @click="createChecklist">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </div>
          <div v-else>
            <v-btn fab class="mx-3" @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>

            </v-btn>
            <v-btn v-if="!readOnly" :loading="loading" color="primary" fab class="mx-3" @click="saveChecklist(false)">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn v-if="!readOnly && review !== 'true'" :loading="loading" color="success" fab class="mx-3" @click="validateList">
              <v-icon>mdi-send-check</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-dialog
          v-model="dialog.checklistFinished"
          persistent
          max-width="600"
      >
        <v-card>
          <v-card-title class="headline">
            Checkliste geschlossen
          </v-card-title>
          <v-card-text>Die Checkliste wurde erfolgreich geschlossen und zur Prüfung gegeben.</v-card-text>
          <v-card-text>Sie können zu den Fahrzeugen zurückkehren oder die nächste Checkliste öffnen</v-card-text>
          <v-card-text v-if="nextList">Nächste Checkliste: {{ nextList.title }}</v-card-text>
          <v-card-text v-else>Letzte Checkliste in diesem Fhz, zur Startseite zurückkehren.</v-card-text>
          <v-card-actions v-if="nextList">
            <v-btn @click="openNewList()" :loading="loading" text color="primary">Nächste Checkliste öffnen</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn
                color="primary"
                text
                @click="$router.push({name: 'Home'})"
            >
              Zur Startseite
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog
          v-model="dialog.confirmComplete"
          persistent
          max-width="290"
      >
        <v-card>
          <v-card-title class="">
            Checkliste abschliessen?
          </v-card-title>
          <v-card-text>Die Checkliste wird als ausgefüllt markiert und kann danach nicht mehr bearbeitet werden.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color=""
                text
                @click="dialog.confirmComplete = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="primary"
                text
                :loading="loading"
                @click="saveChecklist(true)"
            >
              Weiter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="dialog.video"
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Video</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <video controls :src="videoSrc" class="w100"></video>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog.video = false"
            >
              Schliessen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
          v-model="snack.state"
          :timeout="snack.timeout"
          :color="snack.color"
          elevation="12"
          top
          right
      >
        {{ snack.message }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PopulateChecklist",
  props: ['id', 'newList', 'readOnly', 'review'],
  //todo props are not saved on refresh, newList is lost
  data() {
    return {
      checklist: null,
      nextList: null,
      checklists: null,
      items: [],
      videoSrc: '',
      showRemark: [],
      dialog: {
        confirmComplete: false,
        video: false,
        checklistFinished: false
      },
      loading: true,
      radioRules: v => !!v || 'Muss ausgefüllt sein',
      textRules: [v => !!v || 'Muss ausgefüllt sein'],
      snack: {
        state: false,
        color: 'success',
        timeout: 2000,
        message: 'Checkliste wurde erfolgreich gespeichert!',
      }
    }
  },
  mounted() {

  },
  created() {
    if (this.newList === 'true' || this.newList === undefined) {
      this.loadNewChecklist()
    } else {
      this.loadExistingList()
    }
  },
  computed: {

  },
  methods: {
    getChecklists() {
      axios
          .get(process.env.VUE_APP_API_URL + '/checklists/by_vehicle/' + this.checklist.vehicle)
          .then((response) => {
            this.checklists = response.data.checklists

            let openLists = response.data.openLists

            console.log(openLists)
            openLists = openLists.filter((el) => {
              return el.identifier !== this.checklist.identifier
            })
            console.log(openLists)

            if (openLists.length > 0) {
              this.nextList = openLists[0]
            }
          })
          .catch((err) => {
            console.log(err)
            this.showAlert('Serverfehler. Daten konnten nicht geladen werden.', 'error')
          })
          .finally(() => {
            this.loading = false
          })
    },
    loadNewChecklist() {
      this.loading = true
      axios
          .get(process.env.VUE_APP_API_URL + '/checklists/' + this.id)
          .then((response) => {
            this.checklist = response.data

            let i = 0
            this.checklist.items.forEach((e) => {
              this.showRemark[i] = true
              this.items[i] = {
                is_ok: null,
                remark: '',
                _id: e._id,
                title: e.title,
                body: e.body
              }
              i++;
            })
          })
          .catch((err) => {
            console.log(err)
            this.showAlert('Serverfehler. Daten konnten nicht geladen werden.', 'error')
          })
          .finally(() => {
            this.loading = false
          })
    },
    setShowRemark(index, val) {
      //set value to show only when radio value is false -> not ok
      this.$set(this.showRemark, index, val)
    }
    ,
    loadExistingList() {
      this.loading = true
      axios
          .get(process.env.VUE_APP_API_URL + '/pchecklists/' + this.id)
          .then((response) => {
            this.checklist = response.data
            let i = 0
            this.checklist.items.forEach((e) => {
              this.showRemark[i] = e.is_ok || false
              this.items[i] = {
                is_ok: e.is_ok,
                remark: e.remark,
                _id: e._id,
                title: e.title,
                body: e.body
              }
              i++;
            })
          })
          .catch((err) => {
            console.log(err)
            this.showAlert('Serverfehler. Daten konnten nicht geladen werden.', 'error')
          })
          .finally(() => {
            this.loading = false
          })
    }
    ,
    createChecklist() {
      this.loading = true
      axios.post(process.env.VUE_APP_API_URL + '/pchecklists/' + this.id, {items: this.items})
          .then((response) => {
            console.log(response)
            if (response.status === 200) {
              this.newList = false
              this.id = response.data._id
              this.showAlert('Checkliste wurde erfolgreich gespeichert!')
              this.loadExistingList()
            }
          })
          .catch((err) => {
            console.log(err)
            this.showAlert('Serverfehler. Daten konnten nicht geladen werden.', 'error')
          })
          .finally(() => {
            this.loading = false
          })
    },
    validateList() {
      if (this.$refs.form.validate() && this.validateForm) {
        this.dialog.confirmComplete = true
      } else {
        this.showAlert('Checkliste kann nicht abgeschlossen werden. Es sind nicht alle Felder ausgefüllt!', 'red')
      }
    },
    validateForm() {
      this.items.forEach((item) => {
        console.log('validated')
        if (item.is_ok == null) {
          return false
        }
      })
      return true
    },
    saveChecklist(close = false) {
      this.loading = true
      axios.patch(process.env.VUE_APP_API_URL + '/pchecklists/' + this.id, {items: this.items})
          .then((response) => {
            console.log(response)
            if (response.status === 200) {
              if (close) {
                this.closeChecklist()
              } else {
                this.loadExistingList()
                this.showAlert('Checkliste wurde erfolgreich gespeichert!')
              }
            } else {
              alert('Fehler! Checkliste wurde nicht gespeichert.')
            }
          })
          .catch((err) => {
            console.log(err)
            this.showAlert('Serverfehler. Daten konnten nicht geladen werden.', 'error')
          })
          .finally(() => {
            this.loading = false
          })
    }
    ,
    closeChecklist() {
      this.loading = true
      axios.patch(process.env.VUE_APP_API_URL + '/pchecklists/' + this.id, {state: 'review'})
          .then((response) => {
            console.log(response)
            if (response.status === 200) {
              this.getChecklists()
              this.dialog.checklistFinished = true
            }
          })
          .catch((err) => {
            console.log(err)
            this.showAlert('Serverfehler. Daten konnten nicht geladen werden.', 'error')
          })
          .finally(() => {
            this.loading = false
          })
    },
    openNewList() {
      if (this.nextList) {
        this.$router.push({name: 'PopulateChecklist', params: {id: this.nextList._id, newList: 'true'}})
        this.dialog.checklistFinished = false
        this.dialog.confirmComplete = false
        this.$router.go()
        this.showAlert('Checkliste wurde geöffnet')

      } else {
        this.showAlert('Es wurde keine Checkliste ausgewählt!', 'red')
      }
    },
    returnHome() {
      this.$router.push({name: 'Home'})
    },
    showVideo(path) {
      this.videoSrc = process.env.VUE_APP_STATIC_URL + '/uploads/' + path
      this.dialog.video = true
    },
    showAlert(message, color = 'success', timeout = 2000) {
      this.snack.message = message
      this.snack.color = color
      this.snack.timeout = timeout
      this.snack.state = true
    }

  }
}
</script>

<style>
.border-right {
  border-right: 1px solid black;
}

.w100 {
  width: 100%;
}

.item-body img {
  max-width: 100%;
  cursor: auto !important;
}

</style>